import React, {useEffect} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import GuestSelect from 'src/components/elements/GuestSelect'
import {GuestSelectType} from 'src/types/guestSelect'
import Popover from '@material-ui/core/Popover'

//todo convert to styles.module.css or tailwind utility classes
const useStyles = makeStyles(({palette, spacing, shadows}) => ({
  menuWrapper: {
    '& .MuiPopover-paper': {
      minWidth: 384,
      padding: spacing(0, 2.5),
      // paddingRight: 0,
      background: palette.common.white,
      marginTop: '15px !important',
      boxShadow: shadows[2],
      borderRadius: 16,
      marginLeft: -18,
    },

    '& .MuiPaper-root': {
      maxHeight: '100%',
      overflowY: 'inherit',
      marginTop: spacing(3),
      '& ul': {
        '& li': {
          '& span': {
            color: palette.grey[800],
            textTransform: 'capitalize',
          },
        },
      },
    },
  },
}))

export default function GuestSelectMenu(props: {
  maximumGuests?: number | null | undefined
  maximumAnimals?: number | null | undefined
  rules?: string[] | null
  anchorEl: null | HTMLElement
  guests: GuestSelectType
  onChange: (guests: GuestSelectType) => void
  handleClose: () => void
}) {
  const {
    rules,
    anchorEl,
    guests,
    maximumGuests,
    maximumAnimals,
    onChange,
    handleClose,
  } = props

  const classes = useStyles()

  const open = Boolean(anchorEl)

  useEffect(() => {
    if (open) {
      return
    }

    for (const key in guests) {
      if (isNaN(guests[key as keyof typeof guests])) {
        onChange({...guests, [key]: 0})
      }
    }
  }, [guests, onChange, open])

  return (
    <Popover
      className={classes.menuWrapper}
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      elevation={2}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <GuestSelect
        rules={rules}
        maximumGuests={maximumGuests}
        maximumAnimals={maximumAnimals}
        guests={guests}
        onChangeGuest={onChange}
      />
    </Popover>
  )
}
